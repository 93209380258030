import "./../../styles/contact-us.scss"
import formStyles from "./../../styles/commons/form.module.scss"
import React, { useRef, useState } from "react"
import Layout from "../../components/layout/layout"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { graphql, navigate, useStaticQuery } from "gatsby"
import FormError from "../../components/forms/form-error/form-error"
import CountrySelect from "../../components/forms/country-select/country-select"
import { REGEX_EMAIL, REGEX_PHONE_NUMBER } from "../../utils/regex"
import {
  FULL_NAME_MAX_LEN,
  FULL_NAME_MIN_LEN,
  PRIVACY_POLICY_PAGE_URL,
} from "../../utils/constants"
import { APIHelper } from "../../utils/api-helper"
import SEO from "../../components/seo"
import withAppContext from "../../contexts/app.context"
import { Helmet } from "react-helmet"
import * as Recaptcha from "react-recaptcha"
import { ENV_RECAPTCHA_PUBLIC_KEY } from "../../utils/secrets"
import { Helper } from "../../utils/helper"

const contactUsSchema = Yup.object().shape({
  fullName: Yup.string()
    .trim()
    .min(FULL_NAME_MIN_LEN, "Full name is too Short!")
    .max(FULL_NAME_MAX_LEN, "Full name is too Long!")
    .required("This field is required"),
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .matches(REGEX_EMAIL, "Please enter a valid email")
    .required("This field is required"),
  phone: Yup.string()
    .trim()
    .required("This field is required")
    .matches(REGEX_PHONE_NUMBER, "Please enter a valid phone number"),
  companyName: Yup.string()
    .trim()
    .required("This field is required"),
  country: Yup.string().required("This field is required"),
  interest: Yup.string().required("This field is required"),
  policyAgree: Yup.boolean().oneOf(
    [true],
    "You must agree to Volterra's privacy policy"
  ),
})

const ContactUsPage = ({ location }) => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "company__contact-us" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
      },
    },
  } = queryResult

  const formWrapperRef = useRef()

  const [requestSending, setRequestSending] = useState(false)
  const [requestSent, setRequestSent] = useState(false)

  let recaptchaInstance
  let formValue

  const onloadCallback = () => {}

  const onFormSubmit = values => {
    formValue = values
    if (ENV_RECAPTCHA_PUBLIC_KEY) {
      recaptchaInstance.execute()
      return
    }
    postForm(values)
  }

  const verifyCallback = token => {
    const request = {
      ...formValue,
      token,
    }
    postForm(request)
  }

  const postForm = values => {
    const { policyAgree, ...data } = contactUsSchema.cast(values)

    setRequestSending(true)
    APIHelper.contactUs(data)
      .then(() => {
        setRequestSending(false)
        setRequestSent(true)
        navigate("/thanks-contact-us")
      })
      .catch(() => {
        setRequestSending(false)
      })
  }

  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
                "@context": "http://schema.org",
                "@type": "Corporation",
                "additionalType":"http://www.productontology.org/doc/Platform_as_a_service",
                "@id":"https://www.volterra.io/",
                "legalName": "Volterra Inc",
                "description": "Volterra offers a distributed cloud platform to deploy, connect, secure, observe and operate apps and data globally across multiple clouds and the edge",
                "url":"https://www.volterra.io/",
                "address": {
                  "@type": "PostalAddress",
                  "addressLocality": "San Jose, California, USA",
                  "postalCode": "CA 95134",
                  "streetAddress": "3545 N 1st St"
                },
                "email": "sales@volterra.io",
                "name": "Volterra Inc",
                "telephone": "+1 (408) 900-6788",
                "logo":"https://www.volterra.io/img/main/logo.svg",
                "image":"https://www.volterra.io/img/main/apps.png",
                "sameAs" :
                  [
                    "https://twitter.com/volterra_",
                    "https://www.linkedin.com/company/volterra-edge-services/",
                    "https://www.crunchbase.com/organization/volterra-inc"
                  ],
  
                "funder":
                  [
                    {
                      "@type": "Corporation",
                      "legalName": "Mayfield",
                      "url":"https://www.mayfield.com/",
                      "sameAs": "https://en.wikipedia.org/wiki/Mayfield_(company)"
                    },
                    {
                      "@type": "Corporation",
                      "legalName": "Khosla Ventures",
                      "url":"https://www.khoslaventures.com/",
                      "sameAs": "https://en.wikipedia.org/wiki/Khosla_Ventures"
                    },
                    {
                      "@type": "Corporation",
                      "legalName": "(M12) Microsoftís venture fund",
                      "url":"https://m12.vc/",
                      "sameAs": "https://en.wikipedia.org/wiki/M12_(venture_capital)"
                    },
                    {
                      "@type": "Corporation",
                      "legalName": "Samsung NEXT",
                      "url":"https://samsungnext.com/",
                      "sameAs": "https://www.crunchbase.com/organization/samsungnext"
                    },
                    {
                      "@type": "Corporation",
                      "legalName": "Itochu Technology Ventures",
                      "url":"https://www.techv.co.jp/en/",
                      "sameAs": "https://www.crunchbase.com/organization/itochu-technology-ventures"
                    }
                  ],
  
                "owns":
                  [
                    {
                      "@type":"Product",
                      "name":"VoltShare",
                      "url":"https://www.volterra.io/products/voltshare",
                      "image":"https://www.volterra.io/img/products/voltshare.png",
                      "description":"Securely share your critical data while ensuring compliance. Simplify end-to-end encryption. Make your existing collaboration tools more secure."
                    },
                    {
                      "@type":"Product",
                      "name":"VoltMesh",
                      "url":"https://www.volterra.io/products/voltmesh",
                      "image":"https://www.volterra.io/img/products/voltmesh.png",
                      "description":"A SaaS-based offering that delivers high-performance networking and zero-trust security across clouds and edge sites along with the ability to offload apps on our global network"
                    },
                    {
                      "@type":"Product",
                      "name":"VoltStack",
                      "url":"https://www.volterra.io/products/voltstack",
                      "image":"https://www.volterra.io/img/products/voltstack.png",
                      "description":"SaaS-based offering that automates deployment, security and operations of distributed apps and infrastructure across the edge, public/private clouds and our global application delivery network"
                    },
                    {
                      "@type":"Product",
                      "name":"VoltConsole",
                      "url":"https://www.volterra.io/products/voltconsole",
                      "image":"https://www.volterra.io/img/products/voltconsole.png",
                      "description":"A SaaS-based operations and observability portal to manage infrastructure and apps across multi-cloud and edge, provide early warnings and generate actionable business insights"
                    },
                    {
                      "@type":"Product",
                      "name":"Volterra Global Network",
                      "url":"https://www.volterra.io/products/volterra-global-network",
                      "image":"https://www.volterra.io/img/products/global-network.png",
                      "description":"Industry's first app-to-app network and security service that's purpose built to connect and secure your global workloads across multi-cloud and edge"
                    }
                  ]
              }
          `}
        </script>
      </Helmet>

      <SEO
        title={metaTitle}
        pageUrl={"/company/contact-us"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
      />
      <div className="contact-us-container">
        <div ref={formWrapperRef} className="contact">
          <div className="container volterra-container">
            <div className="row flex-column-reverse flex-xl-row">
              <Formik
                initialValues={{
                  fullName: "",
                  email: "",
                  phone: "",
                  companyName: "",
                  country: "",
                  interest: (location.state && location.state.interest) || "",
                  policyAgree: false,
                }}
                validationSchema={contactUsSchema}
                onSubmit={onFormSubmit}>
                {({ errors, touched, values }) => {
                  return (
                    <Form
                      id="website-contact-us"
                      className={`col-12 col-xl-6 ${formStyles.form}`}
                      ves-e2e-test="contact-us/contact-form">
                      <div className={`${formStyles.formGroup} form-row`}>
                        <div className="col-12 col-sm-6 mb-4">
                          <label
                            className={formStyles.formLabel}
                            htmlFor="fullName">
                            Full name *
                          </label>
                          <Field
                            id="fullName"
                            type="text"
                            name="fullName"
                            className={`form-control ${formStyles.formControl}${
                              errors.fullName && touched.fullName
                                ? " is-invalid"
                                : ""
                            }`}
                            placeholder="Enter your name"
                          />
                          <FormError name="fullName" />
                        </div>
                        <div className="col-12 col-sm-6 mb-4">
                          <label
                            className={formStyles.formLabel}
                            htmlFor="email">
                            Email *
                          </label>
                          <Field
                            id="email"
                            type="email"
                            name="email"
                            className={`form-control ${formStyles.formControl}${
                              errors.email && touched.email ? " is-invalid" : ""
                            }`}
                            placeholder="Enter your business email"
                          />
                          <FormError name="email" />
                        </div>
                      </div>
                      <div className={`${formStyles.formGroup} form-row`}>
                        <div className="col-12 col-sm-6 mb-4">
                          <label
                            className={formStyles.formLabel}
                            htmlFor="phone">
                            Phone *
                          </label>
                          <Field
                            id="phone"
                            type="text"
                            name="phone"
                            className={`form-control ${formStyles.formControl}${
                              errors.phone && touched.phone ? " is-invalid" : ""
                            }`}
                            placeholder="Enter your phone number"
                          />
                          <FormError name="phone" />
                        </div>
                        <div className="col-12 col-sm-6 mb-4">
                          <label
                            className={formStyles.formLabel}
                            htmlFor="companyName">
                            Company *
                          </label>
                          <Field
                            id="companyName"
                            type="text"
                            name="companyName"
                            className={`form-control ${formStyles.formControl}${
                              errors.companyName && touched.companyName
                                ? " is-invalid"
                                : ""
                            }`}
                            placeholder="Enter company's name"
                          />
                          <FormError name="companyName" />
                        </div>
                      </div>
                      <div className={`${formStyles.formGroup} form-row`}>
                        <div className="col-12 col-sm-6 mb-4">
                          <label
                            className={formStyles.formLabel}
                            htmlFor="country">
                            Country *
                          </label>
                          <CountrySelect
                            id="country"
                            name="country"
                            className={
                              "form-control" +
                              ` ${formStyles.formControl}` +
                              (errors.country && touched.country
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <FormError name="country" />
                        </div>
                        <div className="col-12 col-sm-6 mb-4">
                          <label
                            className={formStyles.formLabel}
                            htmlFor="interest">
                            Interest in Volterra *
                          </label>
                          <Field
                            id="interest"
                            as="select"
                            name="interest"
                            className={`form-control ${formStyles.formControl}${
                              errors.interest && touched.interest
                                ? " is-invalid"
                                : ""
                            }`}>
                            <option value="" disabled>
                              Choose your interest
                            </option>
                            <option>Talk to Sales</option>
                            <option>Careers and internship</option>
                            <option>Partnership</option>
                            <option>Other</option>
                          </Field>
                          <FormError name="interest" />
                        </div>
                      </div>
                      <div className={`${formStyles.formGroup} form-row`}>
                        <div className="form-check mb-4">
                          <Field
                            id="policyAgree"
                            type="checkbox"
                            name="policyAgree"
                            className={`form-check-input ${
                              errors.companyName && touched.companyName
                                ? " is-invalid"
                                : ""
                            }`}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="policyAgree">
                            I agree to the{" "}
                            <a
                              ves-e2e-test="contact-us/contact-form-policy-link"
                              aria-label="Privacy Policy"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={PRIVACY_POLICY_PAGE_URL}>
                              privacy policy
                            </a>
                          </label>
                          <FormError name="policyAgree" />
                        </div>
                      </div>
                      <div className={`${formStyles.formGroup} form-row`}>
                        <input
                          ves-e2e-test="contact-us/contact-form-submit-btn"
                          aria-label="Contact Us"
                          type="submit"
                          disabled={requestSending || requestSent}
                          value={
                            requestSending || requestSent
                              ? "Sending..."
                              : "Contact us"
                          }
                          className="col btn btn-primary contact-us-btn"
                        />
                      </div>
                    </Form>
                  )
                }}
              </Formik>

              <div
                className="description col-12 col-xl-6"
                ves-e2e-test="contact-us/contact-form-description">
                <h2 className="description__title">Get in touch with us</h2>
                <p className="description__text">
                  Tell us how we can help and we’ll get in touch shortly
                </p>
                <div className="description__list">
                  <div className="description__email">
                    <p className="description__email-title">Sales Inquiries</p>
                    <a
                      aria-label="Mail to Sales"
                      className="description__email-link"
                      href="mailto:sales@volterra.io">
                      sales@volterra.io
                    </a>
                  </div>
                  <div className="description__email">
                    <p className="description__email-title">Job Inquiries</p>
                    <a
                      aria-label="Mail for Job Inquiries"
                      className="description__email-link"
                      href="mailto:hr@volterra.io">
                      hr@volterra.io
                    </a>
                  </div>
                  <div className="description__email">
                    <p className="description__email-title">
                      Technical Support
                    </p>
                    <a
                      aria-label="Mail for Technical Support"
                      className="description__email-link"
                      href="mailto:support@volterra.io">
                      support@volterra.io
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="offices" ves-e2e-test="contact-us/offices-section">
            <div className="container">
              <div className="row justify-content-center justify-content-xl-start">
                <div className="col-12 col-sm-6 col-xl-3">
                  <div className="offices__body">
                    <h5 className="offices__title">Headquarters</h5>
                    <div className="offices__country">
                      <div className="offices__country-flag offices__country-flag--usa"></div>
                      <span className="offices__country-office">USA</span>
                    </div>
                    <p className="offices__description">
                      3545 N 1st St
                      <br />
                      San Jose, CA 95134
                      <br />
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-xl-3">
                  <div className="offices__body">
                    <h5 className="offices__title">EMEA Headquarters</h5>
                    <div className="offices__country">
                      <div className="offices__country-flag offices__country-flag--fr"></div>
                      <span className="offices__country-office">France</span>
                    </div>
                    <p className="offices__description">
                      33 Rue du Mail <br />
                      75002 Paris
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-xl-3">
                  <div className="offices__body">
                    <h5 className="offices__title">APAC Headquarters</h5>
                    <div className="offices__country">
                      <div className="offices__country-flag offices__country-flag--jp"></div>
                      <span className="offices__country-office">Tokyo</span>
                    </div>
                    <p className="offices__description">
                      SS Building 8F, <br />
                      4-2. 3 Chome, <br />
                      Nishi-shinbashi, <br />
                      Minato-ku, Tokyo
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-xl-3">
                  <div className="offices__body">
                    <h5 className="offices__title">Sales Offices</h5>
                    <div className="offices__country offices__country--sales">
                      <div className="offices__country-flag offices__country-flag--jp"></div>
                      <div className="offices__country-wrapper">
                        <span className="offices__country-office">Japan</span>
                        <a
                          aria-label="Mail to Japan sales"
                          href="mailto:japan-inquiry@volterra.io"
                          className="offices__country-contact">
                          Contact Japan sales
                        </a>
                      </div>
                    </div>
                    <div className="offices__country offices__country--sales">
                      <div className="offices__country-flag offices__country-flag--sp"></div>
                      <div className="offices__country-wrapper">
                        <span className="offices__country-office">Madrid</span>
                        <a
                          aria-label="Mail to Spain sales"
                          href="mailto:sales@volterra.io"
                          className="offices__country-contact">
                          Contact Spain sales
                        </a>
                      </div>
                    </div>
                    <div className="offices__country offices__country--sales">
                      <div className="offices__country-flag offices__country-flag--uk"></div>
                      <div className="offices__country-wrapper">
                        <span className="offices__country-office">London</span>
                        <a
                          aria-label="Mail to UK Sales"
                          href="mailto:sales@volterra.io"
                          className="offices__country-contact">
                          Contact UK sales
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {Helper.isBrowser() && ENV_RECAPTCHA_PUBLIC_KEY && (
        <Recaptcha
          ref={e => (recaptchaInstance = e)}
          sitekey={ENV_RECAPTCHA_PUBLIC_KEY}
          onloadCallback={onloadCallback}
          render="explicit"
          size="invisible"
          verifyCallback={verifyCallback}
        />
      )}
    </Layout>
  )
}

export default withAppContext(ContactUsPage)
